<template>
<div class="container-xl">
  <div class="custom row fixed-top overflow-auto bg-light align-items-center">
    <div class="col-lg-6" style="justify-content: center;">
      <form  class="container" style="max-width:450px; " :model="loginDetails" @keyup.enter="onSubmit">
        <div>
          <p class="fs-2" style="color:rgb(77,124,254);"><strong>HOSHO</strong><br/>BLOCKCHAIN SYSTEM</p>
        </div>
        <div>
          <p class="fs-3 mt-4">Sign in</p>
        </div>
        
        <div class="form-floating" style="margin-top: 70px;">
          <input class="form-control" type="text" id="userName" v-model="loginDetails.userName" :class="{ 'is-invalid' : v$.loginDetails.userName.$error}" placeholder=""/>
          <label for="userName"> User Id </label>
          
          <div v-show="v$.loginDetails.userName.$error">
            <span class="text-danger" v-for="error in v$.loginDetails.userName.$errors" :key="error.$uid" >
              {{error.$message}}
            </span>
          </div> 
        </div>
        <div class="form-floating mt-4"> 
        <!-- style="margin-top: 30px;"> -->
          <input class="form-control mb-1" type="password" id="secret" v-model="loginDetails.secret" :class="{ 'is-invalid' : v$.loginDetails.secret.$error}"/>
          <label for="secret" > Password </label>
          
          
          <div v-show="v$.loginDetails.secret.$error">
            <span class="text-danger" v-for="error in v$.loginDetails.secret.$errors" :key="error.$uid" >
              {{error.$message}}
            </span>
          </div>
        </div>
        <div class="form-group d-grid my-4">
          <button type="button" class="btn-lg btn-primary" @click="onSubmit" :loading="isSigningIn">Sign In</button>
        </div>
      </form>
    </div>
    <div class="col-6 d-none d-lg-block" >
      <div class="bg-image d-flex justify-content-center align-items-center" style="background-image: url(https://cdn.wallpapersafari.com/64/37/owb2Zt.jpg); height: 100vh; background-repeat: no-repeat; background-position:center;"></div>
      <!-- <figure class="figure">
        <img src="https://cdn.wallpapersafari.com/64/37/owb2Zt.jpg" 
        class="img-fluid figure-img" alt="image">
      </figure> -->
    </div>
  </div>
</div>
</template>

<script>
import { required,helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core';
import { mapActions, mapState } from 'vuex';
import { SIGN_IN } from '../store/auth';


export default {
  name: 'login',
  data(){
    return {
      v$:useVuelidate(),
      loginDetails: { 
        userName:'', 
        secret:'' 
      }
    }
  },
  computed(){
    mapState('auth', ['isSigningIn'])
  },
  validations(){
    return {
      loginDetails:{
        userName:{ required: helpers.withMessage('Please enter your user ID.', required)},
        secret:{ required: helpers.withMessage('Please enter your secret.', required)}
      }  
    }
  },
  methods:{
    ...mapActions('auth', [SIGN_IN]),
    onSubmit(){
      this.v$.$validate()
      if (this.v$.$invalid == false){
        const loginDetail = {
          ...this.loginDetails,
          applicationDomain: process.env.VUE_APP_APPLICATION_DOMAIN,
        }
      this.SIGN_IN(loginDetail);
      }
    }
  }
}
</script>

<style>
.custom {
  height: 100%;
  justify-content: center;
}
</style>